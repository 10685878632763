import * as React from 'react';
import type { HeadFC, PageProps } from 'gatsby';
import './index.css';
// @ts-ignore
import dirtCheapLogo from '../images/dirtcheap-logo.svg';
// @ts-ignore
import footerImage1 from '../images/otr-footer-1.png';
// @ts-ignore
import footerImage2 from '../images/otr-footer-2.png';
// @ts-ignore
import footerImage3 from '../images/otr-footer-3.png';
import Layout from '../layouts/Layout';
import HeroBanner from '../components/HeroBanner/HeroBanner';
// @ts-ignore
import hero from '../images/otr-hero.png';
import JobMap from '../components/JobMap/JobMap';
import { graphql, useStaticQuery } from 'gatsby';
import ExploreJobsSection from '../components/ExploreJobsSection/ExploreJobsSection';
import JobsNearMe from '../components/JobsNearMe/JobsNearMe';

const IndexPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout
			title={'On the Run | Employment Opportunities'}
			desc={'On the Run | Employment Opportunities'}
		>
			<HeroBanner />

			<section className="bg-[#0661BB]">
				<div className="container mx-auto my-0 w-full ">
					<div className="h-100 w-full px-[16px]">
						<div className="flex flex-row items-center max-[1062px]:flex-col max-[1062px]:pb-[32px]">
							<img
								className="-mb-[48px] -mt-[48px] -translate-x-[40px] text-white max-[1062px]:mb-0 max-[1062px]:translate-x-0"
								src={hero}
								alt="Dirt Cheap Hero"
								width={600}
								height={370}
							/>
							<JobsNearMe data={data} />
						</div>
					</div>
				</div>
			</section>
			<ExploreJobsSection data={data} />
			<JobMap data={data} />
			<section>
				<div className="mx-auto my-0 mb-20 w-full max-w-[1100px] max-[680px]:w-[420px] max-[480px]:w-[300px] max-[320px]:w-[90%]">
					<div className="font-source-sans-pr w-full px-4 pb-14 pb-40">
						<p className="text-3xl text-[#2C2C2C] max-lg:text-xl">
							Our two greatest strengths are no secret – our
							wonderful customers and our great team members. Here
							are some reasons why it’s great to work at On the
							Run, here are some reasons that may help you decide:
						</p>
						<ul className="py-5 text-lg text-[#2C2C2C]">
							<li className="my-3">
								<strong className="font-bold">
									Full Time Work –
								</strong>{' '}
								Most of our positions are full-time. But we are
								flexible so if part-time is what you need, we
								have that too.
							</li>
							<li className="my-3">
								<strong className="font-bold">
									Low Turnover
								</strong>{' '}
								- On average our employees stay with us more
								than 3 years
							</li>
							<li className="my-3">
								<strong className="font-bold">
									Family Owned
								</strong>{' '}
								– We’re local, small enough to feel like family
								& large enough for many growth opportunities
							</li>
							<li className="my-3">
								<strong className="	font-bold">
									Safe Work Environment
								</strong>{' '}
								– All team members must pass pre-employment
								criminal background and drug screens, and our
								stores all have state of the art surveillance
								video systems.
							</li>
						</ul>
						<h2 className="font-oswald text-dirtcheaptext mt-6 text-5xl max-md:text-3xl">
							EMPLOYER OF CHOICE
						</h2>
						<p className="pt-9 text-lg text-[#2C2C2C]">
							Our overall compensation package makes us stand out
							within the convenience store industry in the St.
							Louis market. We also have a great benefit and wage
							package. Benefits include:
						</p>
						<ul className="py-5 text-lg text-[#2C2C2C]">
							<li className="my-2">Competitive Wages</li>
							<li className="my-2">
								Get Paid Weekly – Your Hard Work, Rewarded
								Faster!
							</li>
							<li className="my-2">
								Receive an opportunity for a raise after just 60
								days!
							</li>
							<li className="my-2">
								Affordable health, dental and vision insurance
							</li>
							<li className="my-2">
								Paid vacation for full AND part-time employees
							</li>
							<li className="my-2">
								Company paid Employee Assistance Program
							</li>
							<li className="my-2">401k with a company match</li>
							<li className="my-2">Service Awards</li>
							<li className="my-2">Company Sponsored Events</li>
							<li className="my-2">Plus many more…</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="bg-[#0661BB]">
				<div className="container mx-auto my-0 w-full max-w-[1170px]">
					<div className="font-source-sans-pro w-full px-[16px] pb-12">
						<div className="flex w-full flex-wrap items-center justify-center">
							<img
								className="-mt-[96px] max-[1062px]:w-80"
								src={footerImage1}
								alt="Footer Image"
								width={340}
								height={340}
							/>
							<img
								className="-mt-[96px] max-[1062px]:w-80 max-[591px]:mt-0"
								src={footerImage2}
								alt="Footer Image"
								width={340}
								height={340}
							/>
							<img
								className="-mt-[96px] max-[1062px]:w-80 max-[871px]:mt-0"
								src={footerImage3}
								alt="Footer Image"
								width={340}
								height={340}
							/>
						</div>
						<div className="w-full text-center">
							<a
								href={'https://careers.dirtcheapfunfun.com'}
								target={'_blank'}
							>
								<p className="font-source-sans-pro pt-[28px] text-[20px] font-normal text-white underline">
									Explore Other Career Opportunities at
								</p>
							</a>
							<img
								className={'mx-auto mt-6'}
								src={dirtCheapLogo}
								alt="On The Run Logo"
								width={157}
								height={113}
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
